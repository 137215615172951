/*
    ----------
*/
$Font : (
    "Default"       : "Space Grotesk"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #FFC786,
    "White"   : #FFFFFF,
    "Black"   : #070707,
    "Grey"    : #7A7A7A,
    "Light"   : #F2F0E6
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 } 
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ----------
*/
.cB3332{ text-underline-offset: 6px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ -webkit-line-clamp: 2 }
.Bt6232{ -webkit-line-clamp: 3 }
.X2a9R4{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.Au5718{ list-style: numeric              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(142deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
:root{
    --css-Header-Grey : #E2DEDE;
}
/* 
    ----------
*/
:root{
    --css-Content-Grey   : #EAEDF0;
    --css-Content-Gray   : #CCCCCC;
    --css-Content-Yellow : #FC7F3A;
}
/* 
    ----------
*/
:root{
    --css-Footer-Black : #161F21;
    --css-Footer-Dark  : #9F9F9F36;
    --css-Footer-Gray  : #9F9F9F;
}
/* 
    ----------
*/
:root{
    --css-Minimal  : 12.5px;
    --css-Standard : 13.5px;
    --css-Large    : 24px;
    --css-Small    : 38px;
    --css-Medium   : 42px;
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .rP408N;
    overflow-x: hidden;
}
/* 
    ----------
*/
.k90k22{
    @include Size( 100%, auto );
    @extend .rP408N;
    .v972b4{
        @include Size( 100%, 45px );
        @extend .u0Bc59;
    }
    .i1i29r{
        @include Size( 100%, 100px );
        @extend .GnG350;
        li{
            a{
                @extend .vOx602;
                @extend .K0cW96;
                @extend .pEO064;
                @extend .Klc797;
                @extend .PlO170;
                padding: 0 24px;
                line-height: 100px;
                z-index: 97;
                &:hover{
                    @extend .vF5k47;
                    @extend .aT591i;
                }
            }
            .a703js{
                @include Start( 0, 70px );
                @include Size( 100%, auto );
                @extend .rP408N;
                @extend .GnG350;
                @extend .hPP928;
                min-width: 250px;
                z-index: 999;
                li{
                    a{
                        @include Size( 100%, 42px );
                        @extend .vOx602;
                        @extend .PlO170;
                        @extend .K0cW96;
                        @extend .a527eB;
                        @extend .pEO064;
                        border-bottom: 1px solid rgba( map-get( $Color, "Black" ), .08 );
                        line-height: 42px;
                        svg{
                            @include Size( 14px, 14px );
                            @extend .Klc797;
                            margin: 3px 0 0 0;
                            path{
                                @extend .Klc797;
                                @extend .sRU313;
                            }
                        }
                    }
                    &:first-child a{
                        @extend .z8o5S4;
                    }
                    &:last-child a{
                        @extend .ob2A34;
                    }
                }
            }
            &:first-child a{
                @extend .C7D19o;
            }
            &:last-child a{
                @extend .dW8M00;
            }
            &:hover ul{
                @extend .V81zg1;
            }
        }
    }
    .mq4603{
        @include Size( 100%, 100px );
        @extend .rP408N;
        z-index: 9999;
        .u0v354{
            @include Size( 100%, 100px );
            @extend .Klc797;
            @extend .C4v153;
            @extend .cH8711;
            z-index: 96;
            img{
                @include Size( 200px, auto );
                @extend .Klc797;
            }
            svg{
                @include Size( auto, 35px );
                @extend .Klc797;
            }
        }
        .tc91k2{
            @include Size( 100%, 44px );
            @extend .u0Bc59;
            @extend .Klc797;
            @extend .C4v153;
            @extend .vOx602;
            @extend .K0cW96;
            @extend .vF5k47;
            @extend .cH8711;
            @extend .iI0402;
            border: 1px solid map-get( $Color, "White" );
            max-width: 154px;
            z-index: 98;
            &:hover{
                @extend .vF5k47;
                @extend .u3I753;
                @extend .M99v3q;
                border: 1px solid map-get( $Color, "Black" );
            }
        }
    }
    .uvx453{
        @include Size( 100%, auto );
        @extend .hPP928;
        .b7376e{
            @include Size( 100%, 70px );
            @extend .rP408N;
            .u661kl{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 150px, auto );
                    @extend .Klc797;
                }
            }
            .t736g7{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 20px, 20px );
                    @extend .Klc797;
                }
            }
            .y4r83a{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 25px, 25px );
                    @extend .Klc797;
                }
            }
        }
    }
    .h3l5b9{
        @include Size( 100%, 100vh );
        @include Start( 0, 70px );
        @extend .rP408N;
        border-top: 1px solid var(--css-Content-Gray);
        z-index: 9999;
        .vz893z{
            @include Size( 100%, 100% );
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 48px );
                    @extend .PlO170;
                    @extend .vOx602;
                    @extend .K0cW96;
                    border-bottom: 1px solid var(--css-Header-Grey);
                    line-height: 48px;
                    &:hover{
                        @extend .vF5k47;
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.s288ei{
    @include Size( 100%, auto );
    @extend .Klc797;
    .o747gy{
        @include Size( 100%, auto );
        @extend .Klc797;
        .s40258{
            @include Size( 100%, auto );
            @extend .Klc797;
            &::before{
                @include Start( 0, 0 );
                @include Size( 100%, 100% );
                @extend .gBt584;
                @extend .M99v3q;
                @extend .S0u16d;
                @extend .vR05U6;
                @extend .d721Vx;
                @extend .K0cW96;
                @extend .R6t03r;
                @extend .J55M9e;
                background-color: rgba( map-get( $Color, "Black" ), .3 );
            }
            .uf9065{
                @include Size( 100%, 100vh );
                @extend .Klc797;
                .c137ai{
                    @extend .M99v3q;
                    @extend .D933nr;
                    @extend .cH8711;
                    font-size: var(--css-Medium);
                    animation: .5s Slide .5s forwards;
                    transform: translateX(-150%);
                }
            }
        }
    }
    .q50uy5{
        @include Size( 100%, auto );
        @extend .Klc797;
        .v9n504{
            @include Size( 100%, auto );
            @extend .Klc797;
            .o344kj{
                @include Size( 100%, auto );
                @extend .Klc797;
                .r3q378{
                    @include Size( 100%, 60px );
                    @extend .u0Bc59;
                    @extend .M99v3q;
                    @extend .D933nr;
                    @extend .N4g4m6;
                    @extend .Wm8G06;
                    max-width: 60px;
                    &:hover{
                        @extend .u3I753;
                    }
                }
            }
        }
        .zz447c{
            @include Size( 100%, auto );
            @extend .Klc797;
            .j693mc{
                @include Size( 100%, 5px );
                @extend .gZ959C;
            }
            .x70372{
                @include Size( 100%, auto );
                @extend .Klc797;
                .ew6559{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    img{
                        @include Size( 100%, auto );
                        @extend .z9X51G;
                    }
                }
            }
        }
        .e31j66{
            @include Size( 100%, auto );
            @extend .Klc797;
            .p23y11{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
        }
    }
    .mw249v{
        @include Size( 100%, 80px );
        @extend .u3I753;
    }
    .ad37g3{
        @include Size( 100%, 70px );
        @extend .Klc797;
        .r2416k{
            @include Size( 100%, 70px );
            @extend .rP408N;
            border-bottom: 5px solid var(--css-Content-Grey);
            .o011m9{
                @include Size( 100%, 70px );
                @extend .Klc797;
                .d22f74{
                    @include Size( 100%, 70px );
                    @extend .PlO170;
                    @extend .vOx602;
                    @extend .K0cW96;
                    @extend .vF5k47;
                    z-index: 999;
                    &::after{
                        @include Size( 100%, 5px );
                        @include Top( 0, 0 );
                        @extend .J55M9e;
                        @extend .u0Bc59;
                        @extend .gBt584;
                        @extend .pL4U70;
                        @extend .Klc797;
                    }
                    &:hover{
                        @extend .vF5k47;
                    }
                    &:hover::after{
                        opacity: 100%!important;
                    }
                }
            }
            &:hover .d22f74::after{
                opacity: 0!important;
            }
        }
        .u3k0j3{
            @include Size( 100%, 70px );
            @extend .Klc797;
            &::after{
                opacity: 100%!important;
            }
        }
    }
    .na820w{
        @include Size( 100%, auto );
        @extend .rP408N;
        .xx3i47{
            @include Size( 100%, 526px );
            @extend .Klc797;
            .s69j76{
                @include Size( 100%, auto );
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
                animation: .5s Slide .5s forwards;
                transform: translateX(-20px);
                line-height: 21px
            }
            .v7d78o{
                @extend .wI860z;
                @extend .vOx602;
                @extend .R6t03r;
                @extend .nS533w;
                line-height: 23px
            }
            .oau798{
                @extend .PlO170;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
            .e114dj{
                @include Size( 100%, 45px );
                @extend .u0Bc59;
                @extend .PlO170;
                @extend .vOx602;
                @extend .K0cW96;
                max-width: 200px;
                line-height: 45px;
                &:hover{
                    @extend .vF5k47;
                    @extend .u3I753;
                    @extend .Klc797;
                    @extend .M99v3q;
                }
            }
        }
        .eg5468{
            @include Size( 100%, auto );
            @extend .Klc797;
            .e2251v{
                @include Size( 100%, 526px );
                @extend .T659Fi;
                @extend .N965Go;
                @extend .F710rg;
                background-image: url( "https://vault.uicore.co/creative-agency/wp-content/uploads/sites/30/2022/07/Creative-Agency-Welcome-Image-1.webp" );
                animation: .5s Slide .5s forwards;
                transform: translateY(100%);
            }
        }
    }
    .i84t5x{
        @include Size( 100%, auto );
        @extend .rP408N;
        .z1520t{
            @include Size( 100%, auto );
            @extend .cH8711;
            .q5s25u{
                @extend .PlO170;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .gx42v3{
                @include Size( 100%, 100px );
                @extend .Klc797;
                .x781vv{
                    @include Size( 100%, 36px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .a527eB;
                    @extend .ob2A34;
                    @extend .rP408N;
                    margin: 0 -24px 0 0;
                    max-width: 36px;
                    z-index: 99;
                    svg{
                        @include Size( 24px, 24px );
                    }
                    &:hover{
                        @extend .rP408N;
                    }
                }
                .ty74f3{
                    @include Size( 100%, 36px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .a527eB;
                    @extend .ob2A34;
                    @extend .rP408N;
                    margin: 0 0 0 -18px;
                    max-width: 24px;
                    z-index: 99;
                    svg{
                        @include Size( 24px, 24px );
                    }
                    &:hover{
                        @extend .rP408N;
                    }
                }
            }
        }
        .m37p4r{
            @include Size( 100%, auto );
            @extend .Klc797;
            .ue641t{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    max-width: 130px
                }
            }
        }
    }
    .o711dy{
        @include Size( 100%, auto );
        @extend .Klc797;
        .oq5t79{
            @include Size( 100%, auto );
            @extend .Klc797;
            .q843vr{
                @include Size( 100%, 500px );
                @extend .T659Fi;
                @extend .N965Go;
                @extend .F710rg;
                background-image: url( "./Media/Image/005.png" );
                background-attachment: fixed;
                &::before{
                    @include Start( 0, 0 );
                    @include Size( 100%, 100% );
                    @extend .gBt584;
                    @extend .M99v3q;
                    @extend .S0u16d;
                    @extend .vR05U6;
                    @extend .d721Vx;
                    @extend .K0cW96;
                    @extend .R6t03r;
                    font-size: var(--css-Medium);
                    content: "‘Showcase your work at the cover of the market’";
                    background-color: rgba( map-get( $Color, "Black" ), .5 );
                }
            }
        }
    }
    .oj1a97{
        @include Size( 100%, auto );
        @extend .Klc797;
        .g3285g{
            @include Size( 100%, auto );
            @extend .Klc797;
            .vv75g0{
                @include Size( 100%, auto );
                @extend .rP408N;
                img{
                    @include Size( 100%, auto );
                }
            }
            .f4ab27{
                @include Size( 100%, auto );
                @extend .rP408N;
                img{
                    @include Size( 100%, auto );
                }
            }
        }
        .u2k9z3{
            @include Size( 100%, auto );
            @extend .Klc797;
            .x11q7x{
                @include Size( 100%, auto );
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-Large);
                line-height: 40px
            }
            .pxr905{
                @extend .wI860z;
                @extend .vOx602;
                @extend .R6t03r;
                @extend .nS533w;
                line-height: 23px
            }
            .h8697j{
                @extend .PlO170;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
            hr{
                @include Size( 100%, auto );
                @extend .S6pt64;
                @extend .aV498I;
                border: 1px dashed map-get( $Color, "Black" );
            }
        }
    }
    .a6ft70{
        .u2k9z3{
            @include Size( 100%, auto );
            @extend .Klc797;
            .x11q7x{
                @include Size( 100%, auto );
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-Large);
                line-height: 40px
            }
            .pxr905{
                @extend .wI860z;
                @extend .vOx602;
                @extend .R6t03r;
                @extend .nS533w;
                line-height: 23px
            }
            .h8697j{
                @extend .PlO170;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
            hr{
                @include Size( 100%, auto );
                @extend .S6pt64;
                @extend .aV498I;
                border: 1px dashed map-get( $Color, "Black" );
            }
        }
        .q3412g{
            @include Size( 100%, auto );
            @extend .Klc797;
            .m4q81o{
                @include Size( 100%, 204px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .u0Bc59;
                @extend .ob2A34;
                max-width: 204px;
                .b4e26g{
                    @include Size( 100%, 100% );
                    @extend .u0Bc59;
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .M99v3q;
                    @extend .D933nr;
                    font-size: var(--css-Small);
                    border: 1px solid map-get( $Color, "White" );
                }
            }
            .kv554t{
                @extend .wI860z;
                @extend .K0cW96;
                line-height: 24px;
            }
        }
    }
    .x195er{
        @include Size( 100%, auto );
        @extend .rP408N;
        .fu22h2{
            @include Size( 100%, auto );
            @extend .pEO064;
            @extend .a527eB;
            @extend .rP408N;
            .p640i4{
                @include Size( 100%, 36px );
                @extend .u0Bc59;
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
                border-radius: 3px;
                max-width: 36px;
                line-height: 36px
            }
            .j4c93e{
                @extend .PlO170;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 18px
            }
            .e94h32{
                @extend .t3c0N7;
                @extend .J057Si;
                @extend .Bt6232;
                @extend .G7o96W;
                @extend .xG0j09;
                @extend .vOx602;
                @extend .nS533w;
                line-height: 21px
            }
            .jy077r{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .nS533w;
            }
            &:hover .jy077r{
                @extend .Klc797;
                @extend .Q3s2S1;
                @extend .cB3332;
            }
        }
        .y931x3{
            @include Size( 100%, 450px );
            @extend .u0Bc59;
            .q1779z{
                @extend .i260Up;
                @extend .vOx602;
                @extend .K0cW96;
                animation: 3s Slide 3s forwards;
                transform: translateY(-20px);
                line-height: 30px
            }
            .tkv048{
                @extend .wI860z;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
        }
        .c3ev52{
            @include Size( 100%, 450px );
            @extend .rP408N;
            .n8037e{
                @extend .i260Up;
                @extend .vOx602;
                @extend .K0cW96;
                animation: 3s Slide 3s forwards;
                transform: translateY(-20px);
            }
            .y01k42{
                @extend .wI860z;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
        }
        .qk056a{
            @include Size( 100%, 450px );
            @extend .u0Bc59;
            .m768d1{
                @extend .s4U1j1;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .j974ze{
                @extend .wI860z;
                @extend .vOx602;
                @extend .nS533w;
                @extend .yyO233;
                line-height: 23px
            }
        }
        .fu22h2:nth-child(3) .y931x3{
            background: map-get( $Color, "White" );
        }
        .fu22h2:nth-child(4) .y931x3{
            background: map-get( $Color, "White" );
        }
        .fu22h2:nth-child(7) .y931x3{
            background: map-get( $Color, "White" );
        }
        .fu22h2:nth-child(8) .y931x3{
            background: map-get( $Color, "White" );
        }
        .fu22h2:nth-child(11) .y931x3{
            background: map-get( $Color, "White" );
        }
        .fu22h2:nth-child(12){
            margin-left: 50%;
        }
    }
    .a443b9{
        @include Size( 100%, auto );
        @extend .rP408N;
        .c89xw5{
            @include Size( 100%, auto );
            @extend .Klc797;
            .h699k1{
                @include Size( 100%, auto );
                @extend .PlO170;
                @extend .vOx602;
                @extend .K0cW96;
                margin: 48px 0 48px 0;
                padding: 10px 20px 10px 20px;
                background: var(--css-Content-Yellow);
                border-radius: 4px;
            }
        }
        .b1935l{
            @include Size( 100%, auto );
            @extend .Klc797;
            .i189aw{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
            .x8o716{
                @include Size( 100%, auto );
                @extend .Klc797;
                .s727i8{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    @extend .pEO064;
                    @extend .rP408N;
                    @extend .S7gw68;
                    @extend .vOx602;
                    @extend .nS533w;
                    padding: 5px 10px 5px 10px;
                    border: 1px solid map-get( $Color, "Black" );
                }
                .b46n42{
                    @extend .wI860z;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 21px;
                }
                .zbh982{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .nS533w;
                    line-height: 21px;
                }
            }
            &:hover .x8o716 .s727i8{
                @extend .u3I753;
                @extend .M99v3q;
            }
        }
    }
    .o2gk67{
        @include Size( 100%, auto );
        @extend .u0Bc59;
        .s7d04i{
            @include Size( 100%, auto );
            @extend .Klc797;
            .nk96w3{
                @include Size( 100%, 48px );
                @extend .u0Bc59;
                @extend .Klc797;
                @extend .C4v153;
                @extend .ob2A34;
                @extend .PlO170;
                @extend .vOx602;
                @extend .nS533w;
                border-bottom: 1px solid map-get( $Color, "Black" );
                &::placeholder{
                    @extend .vOx602;
                }
            }
            .e74861{
                @include Size( 100%, 48px );
                @extend .C4v153;
                @extend .Klc797;
                @extend .PlO170;
                @extend .vOx602;
                @extend .u0Bc59;
                border: 1px solid map-get( $Color, "Black" );
                border-radius: 48px;
                &:hover{
                    @extend .u3I753;
                    @extend .M99v3q;
                }
            }
        }
    }
    .j1w74y{
        @include Size( 100%, auto );
        @extend .Klc797;
        .v72x3w{
            @include Size( 100%, auto );
            @extend .gZ959C;
            .zy525o{
                @include Size( 100%, auto );
                @extend .Klc797;
                .q818tu{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .ad757r{
                        @include Size( 100%, 45px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .xG0j09;
                        @extend .vOx602;
                        &::placeholder{
                            @extend .xG0j09;
                            @extend .vOx602;
                        }
                    }
                    .i556so{
                        @include Size( 100%, 150px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .xG0j09;
                        @extend .vOx602;
                        &::placeholder{
                            @extend .xG0j09;
                            @extend .vOx602;
                        }
                    }
                    .g17x42{
                        @include Size( 100%, 48px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .u0Bc59;
                        @extend .vOx602;
                        @extend .K0cW96;
                        font-size: var(--css-Standard);
                        border: 1px solid map-get( $Color, "Default" );
                        &:hover{
                            @extend .u3I753;
                            @extend .M99v3q;
                            border: 1px solid map-get( $Color, "Black" );
                        }
                    }
                    .k70q02{
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                }
            }
        }
        .p563v7{
            @include Size( 100%, auto );
            @extend .Klc797;
            .a56651{
                @extend .i260Up;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .j99di5{
                @extend .wI860z;
                @extend .vOx602;
                @extend .nS533w;
            }
            .j9s090{
                @include Size( 100%, auto );
                @extend .Klc797;
                .s1z891{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .a3343b{
                        @extend .wI860z;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.g69652{
    @include Size( 100%, auto );
    @extend .Klc797;
    .w6v717{
        @include Size( 100%, 100px );
        @extend .Klc797;
        background: var(--css-Footer-Black);
        border-bottom: 1px solid var(--css-Footer-Dark);
        .z8j92s{
            @include Size( 100%, 100px );
            @extend .Klc797;
            .v97o6l{
                @include Size( 100%, 100px );
                @extend .Klc797;
                svg{
                    @include Size( 96px, auto );
                    @extend .Klc797;
                    path{
                        @extend .f8T6I8;
                    }
                }
            }
            .fb596t{
                @include Size( 100%, 100px );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        svg{
                            @include Size( 17.5px, 17.5px );
                            @extend .f8T6I8;
                        }
                    }
                }
            }
        }
    }
    .v80y78{
        @include Size( 100%, auto );
        background: var(--css-Footer-Black);
        .y131cy{
            @include Size( 100%, auto );
            @extend .Klc797;
            .a93n9n{
                @include Size( 100%, auto );
                @extend .M99v3q;
                @extend .K0cW96;
                font-size: var(--css-Standard);
            }
            .opq677{
                @include Size( 100%, auto );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .K0cW96;
                        @extend .vF5k47;
                        @extend .Klc797;
                        font-size: var(--css-Minimal);
                        color: var(--css-Footer-Gray);
                        margin: 0 0 9px 0;
                        line-height: 19px;
                        &:hover{
                            @extend .vF5k47;
                            @extend .M99v3q;
                        }
                    }
                }
            }
            .k09j65{
                @include Size( 100%, 50px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .cH8711;
                background: var(--css-Footer-Black);
                .ke0707{
                    @include Size( 100%, 50px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .ob2A34;
                    @extend .K0cW96;
                    @extend .M99v3q;
                    background: var(--css-Footer-Black);
                    border-bottom: 1px solid var(--css-Footer-Dark);
                    font-size: var(--css-Minimal);
                    &::placeholder{
                        color: var(--css-Footer-Gray)
                    }
                }
                .w3626o{
                    @include Size( 100%, 50px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .ob2A34;
                    background: var(--css-Footer-Black);
                    svg{
                        @include Size( 15px, 15px );
                        @extend .Klc797;
                        path{
                            fill: var(--css-Footer-Gray)
                        }
                    }
                }
            }
        }
        .col-md-9 .col-md-3{
            @include Size( 20%, auto );
        }
    }
    .azw915{
        @include Size( 100%, 70px );
        background: var(--css-Footer-Black);
        .a3r27y{
            @include Size( 100%, 70px );
            background: var(--css-Footer-Black);
            border-top: 1px solid var(--css-Footer-Dark);
            .r8038t{
                @include Size( 100%, 70px );
                @extend .Klc797;
                .m1c5x2{
                    @extend .K0cW96;
                    font-size: var(--css-Minimal);
                    color: var(--css-Footer-Gray);
                }
            }
            .bz803k{
                @include Size( 100%, 70px );
                @extend .GnG350;
                li{
                    a{
                        @extend .K0cW96;
                        @extend .vF5k47;
                        @extend .Klc797;
                        font-size: var(--css-Minimal);
                        color: var(--css-Footer-Gray);
                        &:hover{
                            @extend .M99v3q;
                        }
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.t9237r{
    @include Size( 100%, 5px );
    @extend .gZ959C;
}
/* 
    ----------
*/
@keyframes Slide{
    to{
        transform: translateX(0);
    }
}